import React, {memo} from "react";
import "./study-section.scss"
import TextBlock from '../case-study/text-block'
import ImageBlock from '../case-study/image-block'
import ThreeUpBlock from '../case-study/three-up-block'
import Credits from '../case-study/credits'

const StudySection = (props) => {
	// console.log('Study Section')
	let count = 0
	return (
			<section className="study-section" id={props.section.anchorText}>
				{props.section.blocks && props.section.blocks.map((block, i) => {
					block.__typename === "ContentfulTextBlock" && count++ // +1 if text block
					let string = props.index + 1 + '.' + count
					switch(block.__typename) {
						case "ContentfulTextBlock":
							return <TextBlock block={block} number={string} mainColor={props.mainColor} key={i}/>
						case "ContentfulThreeUpImage":
							return <ThreeUpBlock block={block} key={i}/>
						case "ContentfulImageBlock":
							return <ImageBlock block={block} mainColor={props.mainColor} key={i}/>
						case "ContentfulCreditsSection":
							return <Credits block={block} number={props.index + 1 + '.1'} mainColor={props.mainColor} key={i}/>
						default:
							// do nothing
					}
				})}
				{props.children}
		</section>
	)
}

export default memo(StudySection)