import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const ImageBlock = (props) => {

	return (
		<div
		className={"study-section__content image-block --" + props.block.sizing}
		>
			{props.block.sizing === 'Fit' &&
			<Img
				className="image-block__image"
				fluid={props.block.image.fluid}
				objectFit="contain"
				style={{backgroundColor: props.block.backgroundColor ? props.block.backgroundColor : props.mainColor}}
				backgroundColor={props.block.backgroundColor ? props.block.backgroundColor : props.mainColor}
			/>
			}
			{props.block.sizing === 'Cover' &&
			<Img
				className="image-block__image"
				objectFit="cover"
				fluid={props.block.image.fluid}
				backgroundColor="#F0F2F5"
			/>
			}
			{props.block.sizing === 'Normal' &&
				<Img
				className="image-block__image"
				fluid={props.block.image.fluid}
				style={props.block.removeShadow ? {boxShadow: "none"} : {}}
				backgroundColor={props.block.removeShadow ? "#F0F2F5" : "#f7f7f7"}
				/>
			}
		</div>
	)
}

export default ImageBlock





// ----------------------------------------------------------------
// Tap to expand. worked better, but the interaction wasnt amazing and the implementation was wack

// import React, { useState, useRef, useEffect } from "react"
// import Img from "gatsby-image/withIEPolyfill"
// import { motion, useAnimation } from 'framer-motion'
// // import { useWindowSize } from '../helpers/util'
// import closeIcon from '../../images/x-icon.svg'

// const ImageBlock = (props) => {

// 	const thumbControls = useAnimation()
// 	const overlayControls = useAnimation()
// 	const [lightboxVisible, setLightboxVisible] = useState(false)
// 	const lightbox = useRef()
// 	const lightboxContainer = useRef()
// 	// let { width: vw } = useWindowSize()

// 	const transition = {
// 		type: "spring",
// 		damping: 20,
// 		mass: 1,
// 		stiffness: 150,
// 		restDelta: .0001
// 	}

// 	const showLightbox = (e) => {
// 		const { top, height } = e.currentTarget.getBoundingClientRect()
// 		const {height: vh, width: vw} = document.getElementById('v-measure').getBoundingClientRect()

// 		if (vw > 450) return

// 		setLightboxVisible(true)
// 		thumbControls.start({
// 			y: -top + 20,
// 			scale: (vh * .75) / height,
// 			opacity: 0,
// 			zIndex: 2,
// 			transition: {
// 				opacity: {
// 					duration: 0,
// 					delay: .78 // .1 after lightbox displays
// 				},
// 				default: transition
// 			}
// 		})
// 		overlayControls.start({
// 			opacity: .8
// 		})
// 	}

// 	const hideLightbox = () => {
// 		setLightboxVisible(false)
// 		thumbControls.set({
// 			opacity: 1,
// 			zIndex: 0
// 		})
// 		thumbControls.start({
// 			scale: 1,
// 			y: 0,
// 			opacity: 1,
// 			transition: transition
// 		})
// 		overlayControls.start({
// 			opacity: 0
// 		})
// 	}

// 	const onLightboxDrag = (e) => {
// 		e.preventDefault()
// 		// doesnt work. somewhow prevent body vertical scrolling
// 	}

// 	// useEffect(() => {
// 	// 	if (lightboxVisible) {
// 	// 		// no cleanup cause it removes dom node??
// 	// 		document.addEventListener('touchmove', (e) => {e.preventDefault()}, {passive: false})
// 	// 	}
// 	// 	return () => {document.removeEventListener('touchmove', (e) => {e.preventDefault()}, {passive: false})}
// 	// }, [lightboxVisible])


// 	return (
// 		<div
// 		className={"study-section__content image-block --" + props.block.sizing}
// 		style={{paddingBottom: props.block.sizing === 'Normal' ? 100 / props.block.image.fluid.aspectRatio + '%' : 0}}
// 		>
// 			{props.block.sizing === 'Fit' &&
// 			<Img
// 				className="image-block__image"
// 				fluid={props.block.image.fluid}
// 				objectFit="contain"
// 				style={props.backgroundColor ? {backgroundColor: props.backgroundColor} : {backgroundColor: props.mainColor}}
// 			/>
// 			}
// 			{props.block.sizing === 'Cover' &&
// 			<Img
// 				className="image-block__image"
// 				objectFit="cover"
// 				fluid={props.block.image.fluid}
// 			/>
// 			}
// 			{props.block.sizing === 'Normal' &&
// 			<>
// 				<div className="image-block__z-provider" style={{zIndex: lightboxVisible ? 5 : 0}}>
// 					<motion.div
// 					className="image-block__image-container"
// 					onClick={showLightbox}
// 					animate={thumbControls}
// 					>
// 					<Img
// 					className="image-block__image"
// 					fluid={props.block.image.fluid}
// 					/>
// 					</motion.div>
// 				</div>
// 				{lightboxVisible &&
// 					<>
// 						<motion.div
// 						className="lightbox__overlay"
// 						animate={{
// 							opacity: lightboxVisible ? .8 : 0
// 						}}
// 						style={{
// 							touchAction: lightboxVisible ? "none" : "auto",
// 							pointerEvents: lightboxVisible ? "auto" : "none"
// 						}}
// 						/>
// 						<div
// 						className="lightbox"
// 						ref={lightboxContainer}
// 						style={{width: 'calc(' + props.block.image.fluid.aspectRatio * 75 * 2 + 'vh - 100vw + 40px)'}}
// 						>
// 							<motion.div
// 							className="lightbox__slider"
// 							ref={lightbox}
// 							initial={{opacity: 0}}
// 							animate={{opacity: 1, transition: {duration: 0, delay: .76}}}
// 							drag="x"
// 							dragConstraints={lightboxContainer}
// 							onDrag={onLightboxDrag}
// 							dragTransition={{
// 								power: 1,
// 								bounceStiffness: 100,
// 								bounceDamping: 30
// 							}}
// 							style={{width: props.block.image.fluid.aspectRatio * 75 + 'vh', height: "75vh"}}
// 							>
// 								<Img
// 									className="lightbox__image"
// 									fluid={props.block.image.localFile.childImageSharp.fluid}
// 								/>
// 							</motion.div>
// 						</div>
// 						<motion.div
// 						className="lightbox__close-button"
// 						onClick={hideLightbox}
// 						initial={{opacity: 0, scale: .5}}
// 						animate={{
// 							opacity: 1,
// 							scale: 1,
// 						transition: {
// 							type: "spring",
// 							damping: 10,
// 							mass: 1,
// 							stiffness: 100,
// 							delay: .2
// 						}}}
// 						>
// 							<img src={closeIcon} alt="Close Overlay"/>
// 						</motion.div>
// 					</>
// 				}
// 			</>
// 			}
// 		</div>
// 	)
// }

// export default ImageBlock
