import React, {memo, useRef} from "react"
import {Link} from "gatsby"
import {motion, useAnimation} from "framer-motion"
import {durations} from '../global/variants'

// Set up read-only vars

const noBounce = (delay) => {
	return {
		type: 'spring',
		damping: 28,
		mass: 1.6,
		stiffness: 150,
		delay: delay,
		restDelta: .01,
	}
}

const textVariants = {
	initial: {
		y: '-50%',
		scale: 1
	},
	go: {
		opacity: 0,
		y: '-50vh',
		scale: .9,
		transition: {
			y: {
				type: 'tween',
				ease: 'easeIn',
				duration: durations.toCase
			},
			opacity: {
				type: 'tween',
				ease: 'easeInOut',
				duration: durations.toCase
			},
			default: {
				type: 'spring',
				damping: 35,
				mass: 2,
				stiffness: 150,
				restDelta: .01,
			}
		}
	}
}



const ReflowBlock = (props) => {

	const bkgControls = useAnimation()
	const leadInControls = useAnimation()
	const titleControls = useAnimation()
	const lineControls = useAnimation()
	const containerRef = useRef()

	const runAnimation = (e) => {
		// Grab values
		const {bottom} = e.currentTarget.getBoundingClientRect()
		let bottomAdjust = bottom - props.windowHeight / 2

		// Set fixed in same position (half hanging off the bottom)
		bkgControls.set({
			position: 'fixed',
			left: 0,
			bottom: -bottomAdjust,
		})
		containerRef.current.style.zIndex="9998" // fixme, should set fixed and zindex on same element

		
		// Move to fill screen
		bkgControls.start({
			y: -bottomAdjust, // cancel the adjust and the 50vw from the css
			transition: noBounce(0)
			// transition: easeIn
		})
		props.anchorNavControls.start({opacity: 0})
		leadInControls.start("go")
		titleControls.start("go")
		lineControls.start("go")
	}

	// console.log('Reflow')

	return (
		<Link
			className="reflow"
			to={`/case-study/${props.next.slug}/`}
			onClick={(e) => {runAnimation(e)}}
			ref={containerRef}
		>

			<motion.div
				className="reflow__bkg"
				style={{backgroundColor: props.next.mainColor}}
				animate={bkgControls}
			/>
			<motion.span
				className="reflow__lead-in"
				initial="initial"
				variants={textVariants}
				animate={leadInControls}
			>Next</motion.span>
			<motion.h4
				className="reflow__title"
				initial="initial"
				variants={textVariants}
				animate={titleControls}
			>{props.next.title}</motion.h4>
		</Link>
	)
}

export default memo(ReflowBlock)