import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const TextBlock = (props) => {
	const Bold = ({ children }) => <span className="bold">{children}</span>
	const Text = ({ children }) => <p>{children}</p>

	const options = {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
		},
		renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i}/>, text]),
	}

	return (
		<div className="study-section__content text-block">
			{props.block.title ? <h3 className="text-block__number" style={{color: props.mainColor}}>{props.number}</h3> : ''}
			<div className="text-block__content">
				{props.block.title ? <h3 className="text-block__title" style={{color: props.mainColor}}>{props.block.title}</h3> : ''}
				<div className="text-block__main">{renderRichText(props.block.mainText, options)}</div>
				{props.block.subText ? <div className="text-block__sub">{renderRichText(props.block.subText, options)}</div> : ''}
			</div>
		</div>
	)
}

export default TextBlock