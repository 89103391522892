import React from "react"
import Img from "gatsby-image"

const ThreeUpBlock = (props) => {

	return (
		<div className="study-section__content three-up-block">
				{props.block.images.map((image, i) => {
					return (
						<div className="three-up-block__image-wrapper" key={i}>
							<Img
								className="three-up-block__image"
								fluid={image.fluid}
								backgroundColor="#F0F2F5"
							/>
						</div>
					)
				})}
		</div>
	)
}

export default ThreeUpBlock