import React, {useEffect, useState} from "react"
import {graphql, navigate} from "gatsby"
import {motion, useAnimation} from "framer-motion"
import {studyVariants} from "../components/global/variants"
import "./case-study.scss"
import StudySection from '../components/case-study/study-section'
import AnchorNav from '../components/case-study/anchor-nav'
import StudyHero from '../components/case-study/study-hero'
import {throttle, debounce, useWindowSize} from '../components/helpers/util'
import ReflowBlock from "../components/case-study/reflow-block"
import {durations} from '../components/global/variants'
import SEO from "../components/global/seo"


export default (props) => {

	const [currentSection, setCurrentSection] = useState('Top')
	let windowSize = useWindowSize()
	const anchorNavControls = useAnimation()
	const isTop = currentSection === 'Top'
	let hoverable = typeof window !== 'undefined' && window.matchMedia('(hover:hover) and (min-width: 1025px)').matches

	// Get CMS vars
	const {
		title,
		role,
		ofInterest,
		liveUrl,
		hero,
		mainColor,
		sections
	} = props.data.contentfulCaseStudy

	// Top buttons animations
	const buttonVariants = {
		exit: {
			y: 50,
			opacity: 0
		},
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				type: 'spring',
				damping: 12,
				mass: 1,
				stiffness: 100,
				delay: durations.toCase + .3
			}				
		}
	}

	// Init current section tracking / highlighting
	// FIXME: This code is way overkill for how its being used on mobile (changing back button color)
	useEffect(() => {

		// Set up vars here so they're accessible in useEffect return function, outside the timeout
		let scrolled
		let windowHeight
		let sectionElements
		let elData = []
		let current = currentSection // saving to variable so event listener can get it (cant get state from listener)
		let isMounted = true // to prevent throttle from running after unmount
		let isVisible
		let afterFreshLoad = false
		let handleResize
		let setCurrent


		// Wait for page transition to do this heavy lifting
		const afterTransition = setTimeout(() => {

			// Add expensive values to vars
			windowHeight = window.innerHeight
			scrolled = window.pageYOffset
			sectionElements = document.querySelectorAll('.study-section')

			// Populate data array
			for (let el of sectionElements) {
				elData.push({
					el,
					id: el.id,
					elHeight: el.offsetHeight,
					elTop: el.getBoundingClientRect().top + scrolled
				})
			}

			// On scroll
			setCurrent = throttle(() => {
				if (isMounted) {
					scrolled = window.pageYOffset
					let vMid = scrolled + (windowHeight / 2)

					// Set current section
					for (let el of elData) {
						// If in view
						if (el.elTop < vMid && el.elTop + el.elHeight > vMid && current !== el.id ) {
							current = el.id
							setCurrentSection(el.id)
						}
					}

					// Hide anchor nav when overlapping w/ reflow block
					// TODO: Only run if anchor nav is displayed (hoverable devices)
					let credits = elData[elData.length - 1]
					if (vMid > credits.elTop + (credits.elHeight * .7)) { // if the 3/4 point of credits passes vmid
						if (isVisible !== false) {
							isVisible = false
							anchorNavControls.start({opacity: 0})
						}
					} else if (isVisible !== true && scrolled !== 0) { // avoiding running when scroll to top (scrolled set to 0)
							isVisible = true
							anchorNavControls.start({opacity: 1})
					}
				}
			}, 100)

			// Update values on resize
			handleResize = debounce(() => {
				for (let el of elData) {
					el.elHeight = el.el.offsetHeight
					el.elTop = el.el.getBoundingClientRect().top + scrolled
				}
				setCurrent()
			}, 400)

			// Update values after initial transition ends - initial state is transformed so the values are wrong at first
			if (!props.oldRoute) {
				afterFreshLoad = setTimeout(() => {
					handleResize()
				}, (durations.first - durations.toCase + .1) * 1000) // subtracting toCase because its nested in the toCase timeout
			}

			document.addEventListener('scroll', setCurrent, {passive: true})
			window.addEventListener('resize', handleResize, {passive: true})

		}, (durations.toCase - .1)* 1000)

		return () => {
			clearTimeout(afterTransition)
			afterFreshLoad && clearTimeout(afterFreshLoad)
			isMounted = false
			document.removeEventListener('scroll', setCurrent, {passive: true})
			window.removeEventListener('resize', handleResize, {passive: true})
		}
	},[])

	// console.log('Study')

	return (
		<>
		{/* Anchornav outside motionpage because its fixed */}
		{hoverable && <AnchorNav sections={sections} currentSection={currentSection} isTop={isTop} oldRoute={props.oldRoute} anchorNavControls={anchorNavControls}/>}
		<motion.main
			key={props.location.pathname}
			className="motion-page study"
			custom={props.oldRoute}
			initial="initial"
			animate="animate"
			exit="exit"
			variants={studyVariants}
		>
			<SEO title={title} />
			<motion.div
				className={isTop ? "study-buttons -is-top" : "study-buttons"}
				initial= "exit"
				animate="enter"
				variants={buttonVariants}
			>
				<a className="study-buttons__back"  href="/" tabIndex="0" onClick={(e)=> {
						e.preventDefault()
						if (props.oldRoute === '/') { // if coming from home, go back to saved scroll position
							window.history.back()
						} else { // If coming from another study or external, go to homepage top
							navigate('/')
						}
					}}
				>
					<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><title>Back</title><path d="M2.47 10h18.5M9.186 3.305L2.49 10l6.696 6.696" stroke="#3E3E3E" strokeWidth="1.5"/></svg>
				</a>
				{liveUrl ? (
					<a className="study-buttons__link-out" tabIndex="0" target="_blank" rel="noreferrer" href={liveUrl}>
						<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><title>View Live Project</title><path d="M15.25 4.758L3.542 16.466M4.751 4.25h11v11" stroke="#3E3E3E" strokeWidth="1.5"/></svg>
					</a>
				) : ''}
			</motion.div>
			<StudyHero
				hero={hero}
				title={title}
				role={role}
				ofInterest={ofInterest}
				mainColor={mainColor}
				oldRoute={props.oldRoute}
				setCurrentSection={setCurrentSection}
			/>
			{sections && sections.map((section, i) => {
				return <StudySection section={section} mainColor={mainColor} key={i} index={i} setCurrentSection={setCurrentSection}></StudySection>
			})}
			<ReflowBlock next={props.pageContext.next} anchorNavControls={anchorNavControls} windowHeight={windowSize.height} />
		</motion.main>
		</>
	)
}

export const pageQuery = graphql`
	query caseStudyQuery($slug: String!){
		contentfulCaseStudy(slug: {eq: $slug}) {
			title
			hero {
				fluid {
					...GatsbyContentfulFluid_withWebp_noBase64
				}
			}
			client
			role
			ofInterest
			tags
			slug
			liveUrl
			mainColor
			sections {
				anchorText
				blocks {
					... on ContentfulImageBlock {
						sizing
						image {
							fluid(maxWidth: 2000) {
								...GatsbyContentfulFluid_withWebp_noBase64
								aspectRatio
							}
						}
						backgroundColor
						removeShadow
					}
					... on ContentfulTextBlock {
						id
						mainText {
							raw
						}
						subText {
							raw
						}
						title
					}
					... on ContentfulThreeUpImage {
						id
						images {
							fluid {
								...GatsbyContentfulFluid_withWebp_noBase64
							}
						}
					}
					... on ContentfulCreditsSection { 
						creditList {
							name
							role
						}
					}
				}
			}
		}
	}
`

// localFile {
// 	childImageSharp {
// 		fluid(maxWidth: 2500, quality: 80, srcSetBreakpoints: [2000, 2500]) {
// 			...GatsbyImageSharpFluid_noBase64
// 		}
// 	}
// }