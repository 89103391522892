import React from "react"

const Credits = (props) => {
	return (
		<div className="study-section__content credits">
			<h3 className="credits__number" style={{color: props.mainColor}}>{props.number}</h3>
			<div className="credits__content">
				<h3 className="credits__title" style={{color: props.mainColor}}>Credits</h3>
				<ul>
					{props.block.creditList.map((credit, i) => {
						return (
							<li key={"credit" + i}>
							<span className="credits__name">{credit.name}</span>
							<span className="credits__role">{credit.role}</span>
						</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

export default Credits