import React, {memo} from "react"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import {durations} from '../global/variants'


const containerVariants = (oldRoute) => ({
	initial: {
		opacity: 0,
		y: '50vh'
	},
	animate: {
		opacity: 1,
		y: 0,
		transition: {
			type: 'spring',
			damping: 20,
			mass: 1,
			stiffness: 80,
			delay: oldRoute ? durations.toCase : durations.first,
			staggerChildren: .05,
			delayChildren: oldRoute ? durations.toCase + .1 : durations.first,
			restDelta: 0
		}
	}
})
const childVariants = (skew, finalOpacity) => {
	return {
		initial: {
			opacity: 0,
			y: 100,
			skewY: skew,
			scaleY: 1.2,
		},
		animate: {
			opacity: finalOpacity ? finalOpacity : 1,
			y: 0,
			skewY: '0deg',
			scaleY: 1,
			transition: {
				opacity: {
					type: 'tween',
					ease: 'easeOut',
					duration: .8,
				},
				default: {
					type: 'spring',
					damping: 12,
					mass: 1,
					stiffness: 60,
					restDelta: 0
				},
			}
		}
	}
}

const lineVariants = {
		initial: {
			y: 100,
			scaleX: 0,
		},
		animate: {
			y: 0,
			scaleX: 1,
			transition: {
				scaleX: {
					type: 'spring',
					damping: 20,
					mass: .5,
					stiffness: 60,
					restDelta: 0
				},
				default: {
					type: 'spring',
					damping: 12,
					mass: .9,
					stiffness: 60,
					restDelta: 0
				},
			}
		}
}

const StudyHero = (props) => {

	// console.log('Hero')

	const words = props.title.split(" ")

	return (
		<section className="study-section" id="Top" style={{backgroundColor: props.mainColor}}>
			<motion.div 
				className="study-hero"
				initial="initial"
				animate="animate"
				variants={containerVariants(props.oldRoute)}
			>
				<h1 className="study-hero__title">
					{words.map((word, i) => (
						<motion.span
							key={'title' + i}
							initial="initial"
							variants={childVariants('5deg', 1)}
						>{word}&nbsp;</motion.span>
					))}
				</h1>
				<motion.div variants={lineVariants} className="study-hero__line"/>
				<motion.div variants={childVariants('3deg', .9)} className="study-hero__facts">
					<div className="study-hero__fact">
						<span>Role:</span>
						<span>{props.role}</span>
					</div>
					<div className="study-hero__fact">
						<span>Of Interest:</span>
						<span>{props.ofInterest}</span>
					</div>
				</motion.div>
				<motion.div variants={lineVariants} className="study-hero__line"/>
				<motion.div
					className="study-hero__image-container"
					initial="initial"
					variants={childVariants('0deg', 1)}
				>
					<Img
						className="study-hero__image"
						imgStyle={{objectFit: "contain"}}
						fluid={props.hero.fluid}
					/>
				</motion.div>
			</motion.div>
		</section>
	)
}

export default memo(StudyHero)
