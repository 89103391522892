import React, {memo, useState, useEffect, useRef} from "react"
import {motion, useAnimation} from "framer-motion"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
// import {durations} from '../global/variants'

gsap.registerPlugin(ScrollToPlugin, CustomEase)


	// Read only functions and variables
	// Placed here to avoid re-declaring each render

	// Transition
	const transition = (i, speed) => {
		return {
			type: 'spring',
			damping: 20,
			mass: 1.5,
			stiffness: 250,
			delay: i && speed ? i * speed : 0
		}
	}
	
	// Text animation states
	const textHidden = (i, speed) => {
		return {
			x: 0,
			opacity: 0,
			transition: transition(i, speed)
		}
	}
	const textHover = (i, speed) => {
		return {
			x: 8,
			opacity: .5,
			transition: transition(i, speed)
		}
	}
	const textActive = (i, speed) => {
		return {
			x: 16,
			opacity: 1,
			transition: transition(i, speed)
		}
	}
	
	// Line animation states
	const lineHidden = {
		opacity: .5,
		scaleX: 0
	}
	const linePassive = (i, speed) => {
		return {
			opacity: .5,
			scaleX: .33,
			transition: transition(i, speed)
		}
	}
	const lineHover = (i, speed) => {
		return {
			opacity: .5,
			scaleX: .5,
			transition: transition(i, speed)
		}
	}
	const lineActive = (i, speed) => {
		return {
			opacity: 1,
			scaleX: 1,
			transition: transition(i, speed)
		}
	}

	const ease = CustomEase.create("custom", "M0,0 C0.03,0 0.106,0.684 0.318,0.852 0.464,0.967 0.504,1 1,1 ")

	const scroll = (targetId) => {
		let target = document.getElementById(targetId).offsetTop
		gsap.to(window, {
			duration: 1.5, 
			scrollTo: {
				y: target,
				autoKill: true
			},
			ease: ease
		})
	}




const AnchorNav = (props) => {

	// FIXME: Disable on landscape tablet

	const [isHovered, setIsHovered] = useState(false)
	let isMounted = useRef(false) // Using ref for no re-render

	const textControls = useAnimation()
	const lineControls = useAnimation()

	// let delay = props.oldRoute ? (props.oldRoute.includes('/case-study/') ? durations.caseToCase + .2 : durations.toCase + .2 ) : 2.2
	// delay = delay * 1000 // Convert to milliseconds

	// On initial load, run intro animation after delay and set mounted
	useEffect(() => {
		const timeout = setTimeout(() => {
			textControls.start(({i, id}) => {
				if (id === props.currentSection) {
					return textActive(i, .15)
				} else {
					return textHidden()
				}
			})

			lineControls.start(({i, id}) => {
				if (id === props.currentSection) {
					return lineActive(i, .15)
				} else {
					return linePassive(i, .15)
				}
			})
			isMounted.current = true
		}, 200)

		return () => {
			clearTimeout(timeout)
		}
	}, [])


	// TODO: See if I can optimize this. Runs every hover on and hover off, causing rerender
	// Update on state changes
	useEffect(() => {
		if (isMounted.current) {
			textControls.start(({i, id}) => {
				if (id === props.currentSection || isHovered === id) {
					return textActive()
				} else if (isHovered) {
					return textHover(i, 0)
				} else {
					return textHidden(i, 0)
				}
			})

			lineControls.start(({i, id}) => {
				if (id === props.currentSection || isHovered === id) {
					return lineActive()
				} else if (isHovered) {
					return lineHover(i, 0)
				} else {
					return linePassive(i, 0)
				}
			})
		}
	}, [props.currentSection, isHovered])

	// Add "Top" to anchor link source array for the hero since its not a "section" in cms
	const withTop = props.sections.slice(0)
	withTop.unshift({anchorText: 'Top'})

	// console.log('AnchorNav')

	return (
		<motion.div
			className={props.isTop ? "anchor-nav button -is-top" : "anchor-nav button"}
			animate={props.anchorNavControls}
		>
			{withTop.map((section, i) => {
				return (
				<div
					className={'anchor-nav__link'}
					role="button"
					tabIndex="0"
					onMouseEnter={()=>{setIsHovered(section.anchorText)}}
					onMouseLeave={()=>{setIsHovered(false)}}
					onClick={()=>{scroll(section.anchorText)}}
					onKeyPress={(e)=>{if(e.key === "Enter"){scroll(section.anchorText)}}}
					key={"anchor" + i}
				>
					<motion.div
						className='anchor-nav__line'
						initial={lineHidden}
						custom={{id: section.anchorText, i: i}}
						animate={lineControls}
					></motion.div>
					<motion.span
						className='anchor-nav__text'
						initial={textHidden()}
						custom={{id: section.anchorText, i: i}}
						animate={textControls}
					>{section.anchorText}
					</motion.span>
				</div>
				)
			})}
		</motion.div>
	)
}

export default memo(AnchorNav)